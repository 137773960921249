.projects {
  padding: 20px;
}

.projects > h3 {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  border-bottom: #d90000 5px solid;
  width: fit-content;
  padding-bottom: 10px;
  margin: 40px 0;
  font-size: 1.5rem;
}

.projects > h4 {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  width: fit-content;
  padding-bottom: 10px;
  margin: 40px 0;
  font-size: 1.5rem;
}

.projects__project {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.projects__projectDetails,
.projects__projectImg {
  flex: 0.5;
}

.projects__projectImg > a > img {
  object-fit: cover;
  max-width: 100%;
}

.projects__projectRight {
  flex-direction: row-reverse;
}

@media (max-width: 1100px) {
  .projects__project,
  .projects__projectRight {
    flex-direction: column;
  }
  .projects__projectDetails,
  .projects__projectImg {
    flex: 1;
  }
}

.projects__projectDetails {
  padding: 0 30px;
}

.projects__projectDetails > h4,
.projects__projectDetails > h5 {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 5px solid #d90000;
}

.projects__projectDetails > h5 {
  margin-top: 20px;
}

.projects__projectDetails > p {
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 30px;
}

.projects__projectDetails > a {
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 20px;
  border-radius: 20px;
  background-color: transparent;
  color: #fff;
  border: #b90000 3px solid;
  font-size: 1.2rem;
  cursor: pointer;
}

.projects__projectDetails > a:hover {
  background-color: #b90000;
}

.projects > h4 > a {
  color: #b90000;
  text-decoration: none;
}

.projects > h4 > a:hover {
  color: #d90000;
}
