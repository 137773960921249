.hero {
  height: 80vh;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
  margin-bottom: 20vh;
}

@media (max-width: 600px) {
  .hero {
    flex-direction: column;
    height: fit-content;
  }
}

.hero__left {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  justify-content: center;
  padding: 20px;
}

.hero__left > h1 {
  font-size: 4rem;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.hero__left > h2 {
  font-size: 1.8rem;
  font-weight: 300;
}

.hero__image {
  object-fit: cover;
  max-height: 100%;
  padding: 5px;
}

@media (max-width: 850px) {
  .hero__left > h1 {
    font-size: 3rem;
  }
  .hero__image {
    object-fit: contain;
    max-width: 50%;
    margin: 0 auto;
  }
}
