.about {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.about__left {
  flex: 0.7;
  padding-right: 60px;
}
.about__right {
  flex: 0.3;
}

@media (max-width: 1100px) {
  .about {
    flex-direction: column;
  }

  .about__left,
  .about__right {
    flex: 1;
  }
  .about__left {
    padding-right: 0;
  }
}

.about__left > h3,
.about__right > h3 {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  border-bottom: #d90000 5px solid;
  width: fit-content;
  padding-bottom: 10px;
  margin: 40px 0;
  font-size: 1.5rem;
}

.about__left > p {
  font-size: 1.3rem;
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 30px;
}

.about__left > h4 {
  font-size: 1.4rem;
  font-weight: 300;
}

.about__left > ul {
  list-style: none;
  color: #aaacab;
}

.about__buttons {
  margin-top: 30px;
}

.about__tech {
  display: flex;
  flex-direction: column;
}

.about__tech__major {
  display: flex;
  align-items: center;
  margin: 10px;
}

.about__tech__major {
  font-size: 1.5rem;
}

.about__tech__major > img {
  object-fit: cover;
  height: 4rem;
  margin-right: 15px;
}

@media (max-width: 1100px) {
  .about__tech {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
