.contact {
  padding: 20px;
  margin-bottom: 40px;
}

.contact > h3 {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 3px;
  border-bottom: #d90000 5px solid;
  width: fit-content;
  padding-bottom: 10px;
  margin: 40px 0;
  font-size: 1.5rem;
}

.contact__icons {
  display: flex;
  justify-content: space-around;
}

.contact__icons > a > .MuiSvgIcon-root {
  color: #fff;
  font-size: 5rem;
}

.contact__icons > a:hover > .MuiSvgIcon-root {
  color: #d90000;
  font-size: 5rem;
}
